<template>
    <div>
        <div style="position: relative;">
            <pdf :src="src" :style="design" ref="attachmentFile" :page="page" @num-pages="page_count = $event" :class="customClass"> </pdf>
            
            <i class="fas fa-chevron-left" style="position: absolute; left: 10px; top: 50%; transform: translateY(-50%);" @click="prevPage()"> </i>
            <i class="fas fa-chevron-right" style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%);" @click="nextPage()">  </i>
        </div>
    </div>
</template>

<script>
import pdf from 'vue-pdf'
export default {
    props: ['src', 'design', 'trigger_download', 'download_file_name', 'show_delete', 'customClass'],
    components: {
        pdf
    },
    data() {
        return {
            page: 1,
            page_count: 0,
            meun_actived: false
        }
    },
    methods: {
        download() {
            const link = document.createElement('a');
            link.href = this.src;
            link.download = this.download_file_name +  '.pdf';
            link.click();
        },
        nextPage() {
            if (this.page < this.page_count) {
                this.page++;
            } else {
                alert('No more page');
            }
        },
        prevPage() {
            if (this.page > 1) {
                this.page--;
            } else {
                alert('No more page');
            }
        }
    },
    watch: {
        trigger_download() {
            this.download();
        }
    }
}
</script>

<style>

</style>