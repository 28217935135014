export default {
  Kurdish: {
    employees: "کارمەندەکان",
    add_employee: "زیادکردنی کارمەند",
    id: "کۆد",
    first_name: "ناوی یەکەم",
    last_name: "ناوی دووەم",
    staff: "ستاف",
    phone: "ژ.مۆبایل",
    registration_date: "بەرواری تۆمارکردن",
    salary_type: "جۆری مووچە",
    status: "دۆخ",
    actions: "کردارەکان",
    active: "چالاک",
    deactive: "ناچالاک",
    monthly: "مانگانە",
    daily: "ڕۆژانە",
    employee_information: "زانیاری کارمەند",
    birth_date: "بەرواری لەدایکبوون",
    country: "وڵات",
    iraq: "عراقی",
    foreign: "بیانی",
    expire_passport: "بەسەرچوونی پاسپۆرت",
    food: "خواردن",
    transport: "کرێی هاتووچۆ",
    id_image: "وێنەی تەسکەرە",
    personal_image: "وێنەی کەسی",
    monthly_salary: "مووچەی مانگانە",
    daily_salary: "مووچەی ڕۆژانە",
    hour_salary: "مووچەی کاتژمێر",
    add: "زیادکردن",
    save_changes: "گۆڕینی زانیاری",
    delete_id_image: "سڕینەوەی وێنەی تەسکەرە",
    delete_personal_image: "سڕینەوەی وێنەی کەسی",
    renew_id_image: "تازەکردنەوەی وێنەی تەسکەرە",
    renew_personal_image: "تازەکردنەوەی وێنەی کەسی",
    search: "گـەڕان",
    btn_all_em:'هەموو کارمەندەکان',
    iraq_em:'کارمەندە عێراقیەکان',
    foreign_em:'کارمەندە بیانییەکان',
    salary:"بڕی مووچە"
  },
  English: {
    employees: "Employees",
    add_employee: "Add Employee",
    id: "ID",
    first_name: "First name",
    last_name: "Last name",
    staff: "Staff",
    phone: "Phone",
    registration_date: "Registration Date",
    salary_type: "Salary Type",
    status: "Status",
    actions: "Actions",
    active: "Active",
    deactive: "Deactive",
    monthly: "Monthly",
    daily: "Daily",
    employee_information: "Employee Information",
    birth_date: "Birth Date",
    country: "Country",
    iraq: "Iraq",
    foreign: "Foreign",
    expire_passport: "Expire Passport",
    food: "Food",
    transport: "Transport",
    id_image: "ID Image",
    personal_image: "Personal Image",
    monthly_salary: "Monthly Salary",
    daily_salary: "Daily Salary",
    hour_salary: "Hour Salary",
    add: "Add",
    save_changes: "Save Changes",
    delete_id_image: "Delete ID Image",
    delete_personal_image: "Delete Personal Image",
    renew_id_image: "Re-new ID Image",
    renew_personal_image: "Re-new Personal Image",
    search: "Search",
    btn_all_em:'All Employees',
    iraq_em:'Iraq Employees',
    foreign_em:'Foreign Employees',
    salary:"Salary"
  },
  Arabic: {
    employees: "الموظفين",
    add_employee: "اضافة موظـف",
    id: "رقم",
    first_name: "الاسم الاول",
    last_name: "الاسم الثاني",
    staff: "العاملين",
    phone: "موبایل",
    registration_date: "تاريخ التسجيل",
    salary_type: "نوع الراتب",
    status: "حالة",
    actions: "أجراءات",
    active: "نشيط",
    deactive: "معاق",
    monthly: "شهريا",
    daily: "اليومي",
    employee_information: "معلومات الموظف",
    birth_date: "تاريخ الولادة",
    country: "بلد",
    iraq: "عراقی",
    foreign: "الخارجية",
    expire_passport: "جواز سفر انتهاء الصلاحية",
    food: "طـعام",
    transport: "تكلفة النقـل",
    id_image: "صورة الهوية",
    personal_image: "صورة الشخـصیة",
    monthly_salary: "راتب شهري",
    daily_salary: "راتب یومي",
    hour_salary: "راتب سـاعة",
    add: "اضافة",
    save_changes: "تغيير المعلومات",
    delete_id_image: "حذف صورة الهوية",
    delete_personal_image: "حذف صورة الشخصية",
    renew_id_image: "تحديث صورة الهوية",
    renew_personal_image: "تحديث صورة الشخصية",
    search: "بحث",
    btn_all_em:'كل الموظفين',
    iraq_em:'موظفين عراقيين',
    foreign_em:'الموظفين الأجانب',
    salary:"مبلغ الراتب"
  }
};
